export const COPYRIGHT_TM = 169

export const FOOTER_LINKS = [
  {
    url: 'https://accounts.studentbeans.com/uk/info/privacy',
    text: 'Privacy Policy'
  },
  {
    url: 'https://accounts.studentbeans.com/uk/info/terms',
    text: 'Terms & Conditions'
  },
  {
    url: 'https://www.studentbeans.com/',
    text: 'Student Discounts'
  },
  {
    url: 'https://www.studentbeans.com/graduate-discount',
    text: 'Graduate Discounts'
  },
  {
    url: 'https://partner.studentbeans.com/brand-solutions/beans-id/',
    text: 'BeansiD For Business'
  }
]

export const SELECT_COUNTRY = 'Select your country'
