import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import {
  desktop,
  sister
} from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const footer = css`
  width: 100%;
  text-align: left;
  background-color: ${Colours('huckleberry', '500')};
  z-index: 1;
  padding: 0 20px;

  ${desktop} {
    padding: 0;
  }
`

export const main = css`
  max-width: 580px;
  margin: 0 auto;

  ${sister} {
    max-width: 580px;
  }

  ${desktop} {
    width: 88.333%;
    max-width: 1250px;
  }
`

export const head = css`
  display: flex;
  flex-direction: column;
  margin: 50px auto 20px;

  ${sister} {
    align-items: center;
  }

  ${desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const linkList = css`
  list-style-type: none;
  padding: 0;
  display: inline-block;
`

export const linkListItem = css`
  &:hover {
    text-decoration: underline;
    color: ${Colours('white')};
  }
`

export const footnote = css`
  max-width: 580px;
  margin: 15px auto;

  ${sister} {
    max-width: 580px;
  }

  ${desktop} {
    width: 88.333%;
    max-width: 1250px;
  }
`

export const footnoteContent = css`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${sister} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const divider = css`
  height: 1px;
  border: 0.5px solid ${Colours('liquorice', 350)};
  margin: 5px auto;
`

export const countrySelector = css`
  display: flex;
  align-items: center;
  margin: 10px 5px;

  p {
    margin-right: 10px;
  }
`
