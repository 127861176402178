import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Caption1 from '@thebeansgroup/ui_typography/caption1'
import Subhead from '@thebeansgroup/ui_typography/subhead'

import CountryLink from '@components/country_link'
import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'
import { getNextRoute } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'

import { FOOTER_LINKS, SELECT_COUNTRY } from './constants'
import { getCopyrightText } from './helpers'
import * as styles from './styles'

const Footer = () => {
  const country = useCountryCode()

  const renderHead = () => (
    <div css={styles.head}>
      <a href={getNextRoute('home', { country })}>
        <SafeImage
          src={withAssetPrefix('images/logo_dark.svg')}
          alt='BeansiD logo'
          width={163.5}
          height={28}
        />
      </a>
    </div>
  )

  const renderListItems = (list) =>
    FOOTER_LINKS.map(({ url, text }, index) => {
      return (
        <li
          key={`footer-link-${index}`}
          css={styles.linkListItem}
        >
          <a href={url}>
            <Subhead colour={Colours('white')}>{text}</Subhead>
          </a>
        </li>
      )
    })

  const renderCountryLink = () => (
    <div css={styles.countrySelector}>
      <Caption1 colour={Colours('liquorice', '200')}>{SELECT_COUNTRY}</Caption1>
      <CountryLink
        country={country}
        position='footer'
      />
    </div>
  )

  const renderFootnoteContent = () => (
    <div css={styles.footnoteContent}>
      <Caption1 colour={Colours('liquorice', '200')}>
        {getCopyrightText()}
      </Caption1>
      {renderCountryLink()}
    </div>
  )

  return (
    <footer css={styles.footer}>
      <div css={styles.main}>
        {renderHead()}
        <ul css={styles.linkList}>{renderListItems()}</ul>
      </div>
      <div css={styles.footnote}>
        <div css={styles.divider} />
        {renderFootnoteContent()}
      </div>
    </footer>
  )
}

export default Footer
